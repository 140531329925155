<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading || !isColaborador">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-badge-account-horizontal</v-icon>
        {{ colaborador_original.nome }}
      </v-card-title>

      <v-divider></v-divider>
        <v-card-text class="pa-6">
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-row>
                <!-- Logo -->
                <v-col cols="12" md="3">
                  <!-- <h1 class="text-h6 font-weight-bold mb-4">Avatar</h1> -->
                  <v-card
                    outlined
                    class="fill-height d-flex align-center justify-center"
                    style="position:relative;"
                  >
                    <v-card flat class="pa-4">
                      <v-img
                        v-if="avatar"
                        :src="avatar"
                        contain
                      ></v-img>
                      <v-icon size="200" v-else>mdi-account</v-icon>
                    </v-card>

                    <v-btn
                      fab
                      dark
                      x-small
                      absolute
                      bottom
                      right
                      @click="onButtonClick"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        class="d-none"
                        ref="uploader"
                        @change="onFileChanged"
                      />
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>

                <!-- Dados -->
                <v-col cols="12" md="9" class="">
                  <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>

                  <v-row>
                    <!--Nome  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="colaborador.nome"
                        label="Nome"
                        dense
                        required
                        :rules="[formRules]"
                      ></v-text-field>
                    </v-col>

                    <!-- CPF -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-mask="`###.###.###-##`"
                        v-model="colaborador.cpf"
                        label="CPF"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- RG -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.rg"
                        label="RG"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- ANIVESARIO -->
                    <v-col cols="12" md="4">
                      <DataField
                        label="Data Aniversário"
                        :data_sync.sync="colaborador.data_aniversario"
                      />
                    </v-col>

                    <!-- Entrada -->
                    <v-col cols="12" md="4">
                      <DataField
                        label="Data Entrada"
                        :data_sync.sync="colaborador.data_entrada"
                      />
                    </v-col>

                    <!-- Saida -->
                    <v-col cols="12" md="4">
                      <DataField
                        label="Data Saida"
                        :data_sync.sync="colaborador.data_saida"
                      />
                    </v-col>

                    <!--E-mail  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="colaborador.email_pessoal"
                        label="E-mail Pessoal"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!--E-email_corporativo  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="colaborador.email_corporativo"
                        label="E-mail Corporativo"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- telefone -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.telefone"
                        label="Telefone"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- celular -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.celular"
                        label="Celular"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- whatsapp -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.whatsapp"
                        label="Whatsapp"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!--Telegram -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.telegram"
                        label="Telegram"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Endereco -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
                  <v-row>
                    <!-- CEP -->
                    
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.cep"
                        label="CEP"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!--endereco -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Endereço"
                        v-model="colaborador.endereco"
                        dense
                        readonly
                      >
                      </v-text-field>
                    </v-col>

                    <!--('numero')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Número"
                        v-model="colaborador.numero"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('('bairro')-')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Bairro"
                        v-model="colaborador.bairro"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('('complemento')-')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Complemento"
                        v-model="colaborador.complemento"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Cidade -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Cidade"
                        v-model="colaborador.cidade"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- UF -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="colaborador.uf"
                        label="UF"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Midia -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
                  <v-row>
                    <!-- Instagram -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Instagram"
                        v-model="colaborador.instagram"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Facebook -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Facebook"
                        v-model="colaborador.facebook"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Linkedin -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Linkedin"
                        v-model="colaborador.linkedin"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Youtube -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Youtube"
                        v-model="colaborador.youtube"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn to="/colaborador" exact>
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="updateColaborador" color="secondary" class="white--text">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  fetchEquipeIndividuo,
  putEquipeIndividuo,
} from "@/api/colaborador/empresa_equipe.js";
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Dados",

  components: {DataField: () => import("../../../components/fields/DataField.vue"),},

  data() {
    return {
      loading: false,
      validForm: true,
      logoUpload: null,
      avatar: null,
      tab: 0,
      hoverFile: false,
      colaborador: {},
      colaborador_original: {},
      formRules: inputRequired,
      emailRule: validaEmail,
    };
  },


  computed: {
    ...mapGetters("Usuario", ["isColaborador"]),
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      this.avatarUpload = file;
      this.colaborador.avatar = !this.colaborador.avatar ? "avatar" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
        // vue.editFornecedor();
      };
    },
    getColaborador() {
      if(this.isColaborador){
        return fetchEquipeIndividuo(this.colaborador_id)
        .then((response) => {
          this.colaborador = response;
          this.avatar = this.colaborador.avatar
          this.colaborador_original = { ...this.colaborador };
        })
        .catch(() => {
       
        });
      }
      
    },

    updateColaborador() {
     
      
        if (
        JSON.stringify(this.colaborador) ===
        JSON.stringify(this.colaborador_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const colaborador = new FormData();

        if (this.avatarUpload) {
          colaborador.append("avatar", this.avatarUpload, this.avatarUpload.name);
         
        }
       
        let colaborador_2 = {};
      colaborador_2.bairro = this.colaborador.bairro;
      colaborador_2.cargo_id = this.colaborador.cargo_id;
      colaborador_2.celular = this.colaborador.celular;
      colaborador_2.cep = this.colaborador.cep;
      colaborador_2.cidade = this.colaborador.cidade;
      colaborador_2.complemento = this.colaborador.complemento;
      colaborador_2.cpf = this.colaborador.cpf;
      colaborador_2.email_corporativo = this.colaborador.email_corporativo;
      colaborador_2.email_pessoal = this.colaborador.email_pessoal;
      colaborador_2.endereco = this.colaborador.endereco;
      colaborador_2.facebook = this.colaborador.facebook;
      colaborador_2.id = this.colaborador.id;
      colaborador_2.instagram = this.colaborador.instagram;
      colaborador_2.linkedin = this.colaborador.linkedin;
      colaborador_2.nome = this.colaborador.nome;
      colaborador_2.numero = this.colaborador.numero;
      colaborador_2.rg = this.colaborador.rg;
      colaborador_2.telefone = this.colaborador.telefone;
      colaborador_2.telegram = this.colaborador.telegram;
      colaborador_2.uf = this.colaborador.uf;
      colaborador_2.whatsapp = this.colaborador.whatsapp;
      colaborador_2.youtube = this.colaborador.youtube;
      colaborador_2.status = this.colaborador.status;
        for (let key in colaborador_2) {
          if (colaborador_2[key]) {
            colaborador.append(key, colaborador_2[key]);
          }
        }
        putEquipeIndividuo(this.colaborador.id, colaborador)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Atualizado com sucesso");
            this.loading = false;
            this.getColaborador();
          }
        })
        .catch(() => {
          this.loading = false;
        });
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getColaborador();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
